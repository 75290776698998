<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Comment groups</h3>

	<p>Comment groups provide a way to share comments with a collection of <span v-html="app_name"/> users. (See <span v-html="link('reviewing', 'this help doc')"></span> for guidance on a typical workflow using comment groups to review standards in a framework.)</p>
	<ul>
		<li>Note that users do not need special permission to create or be added to comment groups; any signed-in user can create a comment group and add any other user who has signed in at least once to the group. However, to be able to participate in a comment group for a <span v-html="link('adoption_status', 'private')"></span> framework, users must have been granted <span v-html="link('roles_framework', 'reviewer')"></span> permissions for the framework.</li>
	</ul>
	<p><b>To view or administer comment groups:</b></p>
	<ul>
		<li>First <span v-html="link('signing_in', 'sign in')"></span> to <span v-html="app_name"/>, if you haven’t already.</li>
		<li><img srcset="/docimages/comment_groups.png 2x" class="k-help-img float-right">Then turn on <strong>Comment mode</strong> by clicking the <v-icon small>fas fa-comment</v-icon> icon in the framework toolbar. (If the icon has a white circle around it, comment mode is already on.)</li>
		<li><img srcset="/docimages/groups_icon.png 1x" class="k-help-img float-right">With comment mode on, click on any framework item from the <span v-html="link('treeview', 'tree view')"></span> to view the item <span v-html="link('item_card', 'card')"></span>, then click the <v-icon small class="mr-2">fas fa-users</v-icon>Groups button on the right side of the card to open the <strong>Manage Comment Groups</strong> Menu.</li>
	
	</ul>

	<br>

	<img srcset="/docimages/manage_comment_groups.png 1x" class="k-help-img block">
	<p><b>To create a new group</b>, click <v-icon small class="mr-2">fas fa-plus</v-icon><b>ADD NEW GROUP:</b></p>
	<img srcset="/docimages/comment_groups-2.png 3x" class="k-help-img block">
	<ul>
		<li>Enter a <b>Group Name.</b></li>
		<li>By default, you will be listed as a <b>Group Member</b> and as a <b>Group Admin.</b> Click one of the two buttons to add a new member or admin.</li>
		<li>Upon clicking either button, you will see this dialog:</li>
	</ul>
	<img srcset="/docimages/comment_groups-3.png 3x" class="k-help-img block">
	<ul>
		<li>Click in the box and start typing a last name or email address; once you’ve typed at least 3 characters, the system will show you names/email addresses that match what you've typed. When you find the user you want to add, click ADD USER.</li>

		<li>Once a group has been created, a <b>Group Code</b> will automatically generate in the far right column of the <b>Manage Comment Groups</b> Menu. The <b>Group Code</b> for the CC ELA Comment Group is “NZENZ” as can be seen in the image above.</li>

	<p><b>TIP:</b> When adding large numbers of users to a comment group, Use a Group Code to provide a way for users to join a group themselves.</p>	

		<li>Ensure all users already have access to the framework. Review the <span v-html="link('roles_system', ' System user roles')"></span> and <span v-html="link('roles_framework', 'Framework user roles')"></span> to provide proper access to users. </li>

		<li>Direct users to: 
			<ul>
				<li>sign-in and open the framework</li>
				<li>turn on <b>Comment mode</b> by clicking the  icon in the upper-right corner of the framework. (If the icon has a white circle around it, comment mode is already on.)</li>
				<li>With comment mode on, click on any framework item from the tree view to view the item card, then click the Groups button on the right side of the card to open the <b>Manage Comment Groups</b> Menu.</li>
				<li>Click + <b>JOIN GROUP WITH CODE</b></li>
			</ul>
			<img srcset="/docimages/group_code.png 1.5x" class="k-help-img block">
		</li>
		<li>Provide the 4-letter Group Code and direct all users to click JOIN GROUP</li>
		<li>User names will populate into the <b>All Users</b> column of the <b>Manage Comment Groups</b> Menu.</li>
	
	</ul>
	<p><b>To administer an existing group</b>, click the <v-icon small>fas fa-edit</v-icon> icon next to the group name in the <b>Manage Comment Groups</b> interface.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	